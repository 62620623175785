import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";


import FavoriteIcon from '@mui/icons-material/Favorite';




import { red } from "@mui/material/colors";

import CardLocal from "../cardLocal";
import Video from "./Video"

import FormSection from "../FormSection";
import Gallery from "./Gallery";
import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";



const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="700px" opacity=".5" >
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px'  }}>
                                <h1 style={{ fontSize: '60px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink` }}>Nadia Ștefania</h1>
                                <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                            </h5>
                            <h1 style={{ fontSize: '60px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink` }}>{item.mire} & {item.mireasa}</h1>
                                <h4 style={{ fontSize: '36px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink` }}>{item.savedata}<br />{item.data}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



            <BlogSectionNoi />


            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".5">
                        <div className="row text-center align-items-center mx-lg-5" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px'  }}>
                                <h1 style={{ fontSize: '45px' }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 


            
{/*<Gallery />

           <BlogSectionNasii />*/}
  <CardLocal />

     {/*   {data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={item.img} height="600px" opacity=".2">
                        <div className="row text-center align-items-center mx-lg-5" style={{ position: 'absolute' }}>
                            <div className="col-12" style={{ maxWidth: '550px'  }}>
                                <h1 style={{ fontSize: '70px' }}>{item.title}</h1>
                                <h4 className="mb-4">{item.message}</h4>
                                <h5 className="mb-4">{item.message1}</h5>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h1 style={{ fontSize: '30px' }}>{item.parintii_nume1}</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h1 style={{ fontSize: '30px' }}>{item.parintii_nume2}</h1>
                                    </div>
                                </div>
                                <FavoriteIcon sx={{ color: red[800], fontSize: 40 }} />
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{item.nasii}</h4>
                                <h1 style={{ fontSize: '30px' }}>{item.nasii_nume}</h1>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}
        */}


       

             
            

            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px', color: '#D6B159' }}>
                            {item.mire} & {item.mireasa}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;