
import React, { Component } from "react";




export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
              <h5 className="text-center">
                {this.props.title1}
              </h5>
              <h4 className="text-center p-2">
               <b>{this.props.title2}</b> 
              </h4>
              {/*  <h4 className="text-center pb-5">
                {this.props.message}
              </h4>*/}
              <h5 className="text-center pb-5" >
              Viața ne-a oferit cel mai frumos dar, <br/>
              pe <b>Nadia Ștefania</b>. <br/>
              Am ales ziua de <b>29 octombrie 2022</b>  ca o zi specială din viața noastră de familie. <br/>
             <b>Taina botezului</b>  va avea loc la biserica  <b>"Sfinții Gherman și Casian" ora 14:00</b> <br/>
              alături de nașii noștri <br/>
              <b>Costel și Oana </b> <br/>
              după care va avea loc  <b>cununia noastră:</b> <br/>
              <b>Alexandru și Adelina</b>. <br/>
              Vă invităm să sărbătoriți evenimentele alături de noi la Salon <b>Elite</b>, 
              începând cu ora  <b>19:00</b>. <br/>
              
              </h5>

              <p className="text-center pb-5">Vă rugăm să confirmați prezența dumneavoastră până la data de 15 octombrie 2022 </p>
            </div>
                        </div>
        </div>
                 </div>
              
    );
                }
}

